import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Img } from '@wix/communities-image-lib';

import withFontClassName from '../../../common/hoc/with-font-class-name';
import Link from '../../../common/components/link/internal-link';
import ReadMoreIcon from './read-more-icon';
import styles from './banner-desktop.scss';

class BannerDesktop extends React.Component {
  state = { hover: false, isReadyForAnimations: false };

  componentDidMount() {
    this.setState({ isReadyForAnimations: true });
  }

  handleMouseEnter = () => this.setState({ hover: true });
  handleMouseLeave = () => this.setState({ hover: false });

  hoverHandler = {
    onMouseEnter: this.handleMouseEnter,
    onMouseLeave: this.handleMouseLeave,
  };

  renderLink = (children, className) => {
    const { link } = this.props;
    return (
      <Link
        className={classNames(this.state.hover && styles.hover, className)}
        addHoverClasses={false}
        to={link.to}
        {...this.hoverHandler}
      >
        {children}
      </Link>
    );
  };

  renderTitle = () => {
    const { title, titleFontClassName } = this.props;
    const titleClassNames = classNames(
      styles.title,
      'blog-text-color',
      this.state.hover && 'blog-link-color',
    );
    return (
      <div className={classNames(styles.titleContainer, titleFontClassName)}>
        {this.renderLink(<h1 className={titleClassNames}>{title}</h1>)}
      </div>
    );
  };

  renderAction = () => {
    const { link, contentFontClassName } = this.props;
    const actionClassNames = classNames(
      styles.action,
      'blog-text-color',
      'blog-icon-fill',
      this.state.hover && ['blog-link-color', 'blog-link-fill'],
    );
    return (
      <div className={classNames(styles.actionContainer, contentFontClassName)}>
        {this.renderLink(
          <span className={actionClassNames}>
            {link.text}
            <ReadMoreIcon className={styles.actionIcon} />
          </span>,
        )}
      </div>
    );
  };

  renderDivider = () => (
    <div className={classNames(styles.divider, 'blog-text-background-color')} />
  );
  renderOverlay = () => (
    <div
      className={classNames(styles.imageOverlay, 'blog-link-background-color')}
    />
  );

  renderCategory = () => {
    if (!this.props.category) {
      return null;
    }
    const { category, contentFontClassName } = this.props;
    return (
      <div
        className={classNames(styles.categoryContainer, contentFontClassName)}
      >
        <div className={styles.category}>
          <div className={styles.categoryTitleContainer}>
            <p
              className={classNames(
                styles.categoryTitle,
                'blog-button-primary-text-color',
              )}
            >
              {category}
            </p>
          </div>
          <div className={styles.categoryUnderlineContainer}>
            <div
              className={classNames(
                styles.categoryUnderline,
                'blog-button-primary-text-background-color',
              )}
            />
          </div>
        </div>
      </div>
    );
  };

  renderImage = () =>
    this.renderLink(
      <div className={styles.image}>
        <Img className={styles.imageFit} src={this.props.image} />
        {this.renderOverlay()}
        {this.renderCategory()}
      </div>,
    );

  render() {
    const containerClassNames = classNames(
      styles.container,
      this.state.isReadyForAnimations && styles.animated,
      'blog-card-background-color',
    );
    return (
      <div className={containerClassNames}>
        <div className={styles.left}>
          {this.renderTitle()}
          {this.renderAction()}
          {this.renderDivider()}
        </div>
        {this.renderImage()}
      </div>
    );
  }
}

BannerDesktop.propTypes = {
  title: PropTypes.string.isRequired,
  category: PropTypes.string,
  link: PropTypes.object.isRequired,
  image: PropTypes.object.isRequired,
  titleFontClassName: PropTypes.string.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
};

export default withFontClassName(BannerDesktop);
