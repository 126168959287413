import { __assign } from "tslib";
import createI18nConfig from './create-i18n-config';
import { icuOptions } from '../constants/icu-options';
export var createI18nWithoutICU = function (locale, messages, initI18nWithoutICU) {
    var i18n = initI18nWithoutICU({ locale: locale, messages: messages });
    i18n.init(__assign({}, createI18nConfig()));
    return i18n;
};
export var createI18nWithICU = function (locale, messages, initI18nWithICU) {
    var i18n = initI18nWithICU({ locale: locale, messages: messages, icuOptions: icuOptions });
    i18n.init();
    return i18n;
};
