var weekAgo = function () { return new Date().getTime() - 1000 * 60 * 60 * 24 * 7; };
var minuteAgo = function () { return new Date().getTime() - 1000 * 60; };
export var isLessThanMinute = function (time) { return new Date(time).getTime() > minuteAgo(); };
var isLessThanWeek = function (time) { return new Date(time).getTime() > weekAgo(); };
var isNotCurrentYear = function (time) { return new Date().getYear() !== new Date(time).getYear(); };
export var getTimeAgoFormat = function (time) {
    if (isLessThanWeek(time)) {
        return 'timeRelative';
    }
    if (isNotCurrentYear(time)) {
        return 'fullDate';
    }
    return 'date';
};
