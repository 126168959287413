import {
  createI18nWithoutICU,
  createI18nWithICU,
} from '@wix/communities-blog-client-common';
import {
  initI18n as initI18nWithICU,
  initI18nWithoutICU,
} from '@wix/yoshi-flow-editor';

export const initI18n = (locale, translations, useICU = false) => {
  return useICU
    ? createI18nWithICU(locale, translations, initI18nWithICU)
    : createI18nWithoutICU(locale, translations, initI18nWithoutICU);
};
